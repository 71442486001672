<template>
  <div class="settings">
    <el-form label-position="left" label-width="240px" style="width: 540px">
      <el-form-item label="SMS баланс. руб.">
        <el-input-number v-model="form.sms_balance_rub" :precision="2" disabled />
      </el-form-item>
      <el-form-item label="ЮKassa баланс. руб.">
        <el-input-number v-model="form.yoo_money_balance_rub" :precision="2" disabled />
      </el-form-item>
      <el-divider />
      <el-form-item label="email для обратной связи">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-divider />
      <el-form-item label="Оплата за одно назначение. руб.">
        <el-input-number v-model="form.cost_rub" :precision="2" :step="0.1" :min="0" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">OK</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        cost_rub: 0,
        sms_balance_rub: 0,
        yoo_money_balance_rub: 0
      }
    }
  },

  beforeMount () {
    this.$axios.get('settings')
      .then(({data}) => {
        this.form = {...this.form, ...data}
      })
      .catch(err => {
        console.log(err)
      })
  },

  methods: {
    onSubmit () {
      this.$axios.post('settings', { ...this.form })
        .then(() => {
          this.$notify.success({ message: 'Настройки сохранены' })
        })
        .catch(err => {
          console.log(err)
          if (err.response.data.error) {
            this.$notify.error({ title: 'Error',  message: err.response.data.error })
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.settings {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40px - @header-height);

  .el-input-number {
    width: 100%;
  }
}
</style>
